.ProgressBar {
  width: 100%;
  height: 8px;
  background-color: #b79be5;
  border-radius: 5px; }

.Progress {
  background-color: #673ab7;
  height: 100%;
  margin: 0;
  border-radius: 5px; }
