.upload {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  text-align: left;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 30px; }

.upload-content {
  display: flex;
  flex-direction: row;
  padding-top: 16px;
  box-sizing: border-box;
  width: 100%; }

.files {
  margin-left: 32px;
  align-items: flex-start;
  justify-items: flex-start;
  flex: 1;
  overflow-y: auto; }

.actions {
  display: flex;
  flex: 1;
  width: 100%;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 32px; }

.title {
  margin-bottom: 32px;
  color: #555; }

.filename {
  margin-bottom: 8px;
  font-size: 16px;
  color: #555; }

.Row {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  height: 50px;
  padding: 8px;
  overflow: hidden;
  box-sizing: border-box; }

.checkIcon {
  opacity: 0.5;
  margin-left: 32px; }

.progressWrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center; }
